
'use strict';

var WPCL = WPCL || {};

WPCL.Library = function() {

    this.majorVersion = 41;

    this.instanceId = null;
    this.options = {

        url: 'https://payments.worldpay.com/',
        type: 'lightbox',
        inject: 'default',
        target: null,
        trigger: null,
        lightboxMaskOpacity: 50,
        lightboxMaskColor: '#000000',
        lightboxShadowColor: '#666',
        lightboxShadowSize: '30px',
        accessibility: false,
        language: 'en',
        country: '',
        preferredPaymentMethod: '',
        disableScrolling: false,
        hideContent: false,

        checkoutURL: null,

        successURL: '',
        cancelURL: '',
        failureURL: '',
        pendingURL: '',
        errorURL: '',
        expiryURL: '',

        resultCallback: null,
        flowCallback: null,

        customisation: null,

        debug: false
    };

    this.iframeTitlesLanguageMap = {
        'bg':'Страници за плащане',
        'bs':'Stranice za plaćanje',
        'ca':'Pàgines de pagament',
        'cs':'Platební stránky',
        'cy':'Tudalennau talu',
        'da':'Betalingssider',
        'de':'Zahlungsseiten',
        'el':'Σελίδες πληρωμής',
        'en':'Payment Pages',
        'es':'Páginas de pago',
        'et':'Makse leheküljed',
        'fi':'Maksusivut',
        'fr':'Pages de paiement',
        'hi':'भुगतान पेज',
        'hr':'Stranice za plaćanje',
        'hu':'Fizetési oldalak',
        'it':'Pagine di pagamento',
        'ja':'決済ページ',
        'ko':'결제 페이지',
        'lv':'Maksājumu lapas',
        'nb':'Betalingssider',
        'nl':'Betaalpagina’s',
        'no':'Betalingssider',
        'pl':'Strony płatności',
        'pt':'Páginas do pagamento',
        'ro':'Pagini de plată',
        'ru':'Страницы оплаты',
        'si': 'ගෙවීම් පිටු',
        'sk':'Webové stránky na vykonávanie platieb',
        'sl':'Plačilne strani',
        'sv':'Betalningssidor',
        'tr':'Ödeme Sayfaları',
        'zh':'支付页面',
        'th':'หน้าชำระเงิน',
        'zh_TW': '支付頁面',
        'es_CO':'Páginas de pago',
        'pt_BR':'Páginas de pagamento'
    };

    this.lightboxMaxWidth = 750;

    this.prefix = 'wp-cl';
    this.wpName = this.prefix; 

    this.isPostMessage = false;

    this.sdkInfoAlreadySent = false;

    this.isFakeLoadEvent = false;

    this.onloadInject = null;

    this.postMessageHandler = null;
};

WPCL.Library.prototype = (function(){

    var setup = function (merchantOptions) {

        var self = this;

        writeMessage.call(self, "Setting up library...");

        this.instanceId = new Date().getTime() + Math.floor(Math.random() * 100);

        setOptions.call(self, merchantOptions);

        self.options.iframeUrl = buildUrl.call(self);

        switch (self.options.type) {
            case 'iframe':
                setupIframe.call(self);
                break;
            case 'lightbox':
                setupLightbox.call(self);
                break;
            default:
                writeMessage.call(self, "ERROR: parameter 'type' is not a valid value");
                break;
        }
    };


    var buildUrl = function() {

        var self = this;
        var url = decodeUrl(self.options.url);

        url = url.replace("/hpp/", "/hpp-iframe/");

        url = addUrlParam(url, 'iframeIntegrationId', self.instanceId);

        var checkoutURL = getCheckoutUrl.call(self);
        if (checkoutURL != null) {
            url = addUrlParam(url, "checkoutURL", checkoutURL);
        }

        if (self.options.flowCallback != null) {
            var origin = extractOrigin.call(self);
            url = addUrlParam(url, 'iframeOrigin', origin);
        }

        url = addUrlParam(url, 'language', self.options.language);
        url = addUrlParam(url, 'country', self.options.country);
        url = addUrlParam(url, 'preferredPaymentMethod', self.options.preferredPaymentMethod);
        url = addUrlParam(url, 'disableScrolling', self.options.disableScrolling);

        url = addUrlParam(url, 'successURL', self.options.successURL);
        url = addUrlParam(url, 'cancelURL', self.options.cancelURL);
        url = addUrlParam(url, 'failureURL', self.options.failureURL);
        url = addUrlParam(url, 'pendingURL', self.options.pendingURL);
        url = addUrlParam(url, 'errorURL', self.options.errorURL);
        url = addUrlParam(url, 'expiryURL', self.options.expiryURL);

        writeMessage.call(self, 'url set to: ' + url);

        return url;
    };

    var getCheckoutUrl = function() {
        var self = this;
        var url = self.options.checkoutURL;
        if (url != null) {
            url = url.trim();
            if (url.length == 0) {
                url = null;
            }
        } else {
            url = window.location.href;
        }
        return url;
    };

    var extractOrigin = function() {
        var origin = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
        return origin;
    };

    var isMissing = function(str) {
        return (!str || 0 === str.length);
    };

    var decodeUrl = function decodeUrl(url) {
        if (isMissing(url)) {
            return url;
        } else {
            return url.replace(/&amp;/g,'&');
        }
    };

    var addUrlParam = function(url, name, value) {
        if(isMissing(value) || isMissing(name)) {
            return url;
        } else {
            var paramPattern = new RegExp('[?&]'+name+'=');
            var paramMatch = url.search(paramPattern);
            if(paramMatch === -1) {
                if (url.indexOf("?") === -1) {
                    return url + "?" + name + '=' + encodeURIComponent(value);
                } else {
                    return url + '&' + name + '=' + encodeURIComponent(value);
                }
            } else {
                var nonEmptyParamPattern = new RegExp('[?&]'+name+'=[^&]');
                var nonEmptyParamMatch = url.search(nonEmptyParamPattern);
                if(nonEmptyParamMatch === -1) {
                    return url.replace(paramPattern, '');
                } else {
                    return url;
                }
            }

        }
    };

    var setOptions = function (merchantOptions) {

        var self = this;

        for (var newProperty in merchantOptions) {
            if (self.options.hasOwnProperty(newProperty)) {
                self.options[newProperty] = merchantOptions[newProperty];
            }
        }

        self.wpName += '-';
        self.wpName += self.options.target;

        writeMessage.call(self, "Overriding options with the following:");
        writeMessage.call(self, JSON.stringify(self.options));
    };

    var setupIframe = function() {
        var self = this;

        writeMessage.call(self, "Setting up iframe...");

        messagingSetup.call(self);

        injectIframe.call(self);
    };

    var injectIframe = function() {
        var self = this;

        var target = document.getElementById(self.options.target);
        writeMessage.call(self, 'Injecting iframe into page...');

        if(typeof(target) !== 'undefined' && target !== null) {

            self.onloadInject = function() {
                injectIframeToTarget.call(self);
            };

            if (self.options.inject === 'immediate') {
                injectIframeToTarget.call(self);
            } else if (self.options.inject === 'onload') {

                if (window.addEventListener) {
                    writeMessage.call(self, 'The browser supports addEventListener');
                    window.addEventListener('load', self.onloadInject, false);
                } else if (window.attachEvent) {
                    writeMessage.call(self, 'The  browser supports attachEvent');
                    window.attachEvent('onload', self.onloadInject);
                } else {
                    writeMessage.call(self, 'ERROR: the  browser does not support an onload event-handler');
                }

            } else if (self.options.inject === 'default') {

                window.onload = function (e) {
                    injectIframeToTarget.call(self);
                    if (e && e.target && e.target != document) {
                        self.isFakeLoadEvent = true;
                    }
                };

            } else {
                writeMessage.call(self, 'ERROR: accepted parameters are immediate, onload, default');
            }

        } else {
            writeMessage.call(self, "ERROR: HTML target, specified by 'target' parameter, could not be found - target: " + self.options.target);
        }
    };

    var injectIframeToTarget = function() {
        var self = this;

        var iframeParent = document.createElement("div");
        iframeParent.id = self.prefix;

        buildIframe.call(self, iframeParent);
        injectElementIntoTarget.call(self, iframeParent);

        submitFormData.call(self);
    };

    var getIframeTitleAndLanguageCode = function() {
        var self = this;

        var defaultLanguageCode = "en";
        var titleLanguageRow = {
            "language" : self.options.language,
            "title" : self.iframeTitlesLanguageMap[self.options.language]
        };

        if (isMissing(titleLanguageRow.language) || isMissing(titleLanguageRow.title)) {
                titleLanguageRow.language = defaultLanguageCode;
                titleLanguageRow.title = self.iframeTitlesLanguageMap[defaultLanguageCode];

                writeMessage.call(self, "WARN: language '" + self.options.language + "'not found for parameter 'language', using default instead...");
        }

        return titleLanguageRow;
    };

    var setupLightbox = function() {
        var self = this;

        var valid = true;
        writeMessage.call(self, "Setting up lightbox...");

        if (checkNumber(self.options.lightboxMaskOpacity)){
            writeMessage.call(self, "ERROR: parameter 'lightboxMaskOpacity' is NaN: " + self.options.lightboxMaskOpacity);
            valid = false;
        }

        if (valid){
            messagingSetup.call(self);

            bindLightbox.call(self);
        }
    };

    var bindLightbox = function() {
        var self = this;

        var btnCheckout = document.getElementById(self.options.trigger);

        writeMessage.call(self, "Binding lightbox...");

        if (typeof(btnCheckout) !== 'undefined' && btnCheckout !== null) {

            btnCheckout.onclick = function() {
                injectLightbox.call(self);
                return false;
            };

            writeMessage.call(self, "Binded lightbox");

        } else {
            writeMessage.call(self, "ERROR: lightbox trigger (element) could not be found - trigger: " + self.options.trigger);
        }

    };

    var injectLightbox = function() {
        var self = this;
        var target = document.getElementById(self.options.target);

        writeMessage.call(self, "Injecting lightbox...");

        if (typeof(target) !== 'undefined' && target !== null) {

            var lightboxContainer = document.createElement("div");
            lightboxContainer.id = self.prefix;

            var lightboxMask = document.createElement("div");
            lightboxMask.id = self.prefix + "-mask";
            lightboxMask.setAttribute("style",
                "position: fixed;" +
                "top:0;" +
                "left:0;" +
                "background: " + self.options.lightboxMaskColor + ";"+
                "width: 100%;" +
                "height: 100%;" +
                "-ms-filter: \"progid:DXImageTransform.Microsoft.Alpha(Opacity=" + self.options.lightboxMaskOpacity + ")\";" +
                "filter: alpha(opacity=" +  self.options.lightboxMaskOpacity + ");" +
                "opacity: " + (self.options.lightboxMaskOpacity / 100) + ";" +
                "z-index: 100005;" +
                "-webkit-transform: translate3d(0,0,0);"
            );
            lightboxContainer.appendChild(lightboxMask);

            var lightboxContent = document.createElement("div");
            lightboxContent.id = self.prefix + "-content";
            lightboxContent.setAttribute("style",
                "position:fixed;" +
                "top:0;" +
                "left:0;" +
                "bottom:0;" +
                "right:0;" +
                "width:100%;" +
                "z-index:100006;" +
                "padding:16px;" +
                "box-sizing:border-box;" +
                "overflow:scroll;" +
                "-webkit-overflow-scrolling:touch;"
            );
            lightboxContainer.appendChild(lightboxContent);

            var lightboxDialog = document.createElement("div");
            lightboxDialog.id = self.prefix + "-lightbox";
            lightboxDialog.setAttribute("role", "dialog");
            lightboxDialog.setAttribute("style",
                "top:60px;" +
                "z-index: 100006;" +
                "margin: 0 auto;" +
                "max-width:" + self.lightboxMaxWidth + "px;"

            );
            lightboxContent.appendChild(lightboxDialog);

            var lightboxIframeContent = document.createElement("div");
            lightboxIframeContent.id = self.wpName;
            lightboxDialog.appendChild(lightboxIframeContent);

            buildIframe.call(self, lightboxIframeContent);

            injectElementIntoTarget.call(self, lightboxContainer);

            if (self.options.accessibility) {
                bindLightboxAccessibility.call(self);
            }

            scroll.call(self, 0);


            lightboxFocus.call(self);

            submitFormData.call(self);

        } else {
            writeMessage.call(self, "ERROR: HTML target, specified by 'target' parameter, could not be found - target: " + self.options.target);
        }

    };

    var lightboxFocus = function () {
        var self = this;
        var element = document.getElementById(self.wpName + "-iframe");
        if (element != null && document.activeElement != element) {
            window.setTimeout(function () {
                element.focus();
            }, 0);
        }
    };

    var buildIframe = function(iframeParent) {
        var self = this;

        var customisation = self.options.customisation;
        var isIframe = (self.options.type == "iframe");
        var isLightbox = (self.options.type == "lightbox");
        var isCustomisation = (customisation != null);
        var iframeTitleAndLanguage = getIframeTitleAndLanguageCode.call(self);
        var hideContent = self.options.hideContent;

        var iframe = document.createElement("iframe");
        iframe.id = self.wpName + "-iframe";
        iframe.name = self.wpName + "-iframe";
        iframe.title = iframeTitleAndLanguage.title;
        if (!isCustomisation) {
            iframe.src = self.options.iframeUrl;
        }
        iframe.setAttribute("class", "wp-cl-iframe");
        iframe.setAttribute("allowtransparency", "yes");
        iframe.setAttribute("scrolling", "no");
        iframe.setAttribute("frameBorder", "0");
        iframe.setAttribute("border", "0");

        iframe.setAttribute("allow", "payment");
        iframe.setAttribute("allowpaymentrequest", "");

        try {
            iframe.allowPaymentRequest = true;
        } catch (e) {
            writeMessage.call(self, "WARN: failed to allow payment request: " + e);
        }

        var defaultHeight = isIframe && hideContent ? 0 : 400;
        iframe.setAttribute("style",
            "width: 1px;" +
            "min-width: 100%;" +
            "*width: 100%;" +
            "border:0;" +
            "background: transparent;" +
            "height:" + defaultHeight + "px;" +

            (isIframe ?
                (hideContent ? "display: block; visibility:hidden;" : "")
            : "") +

            (isLightbox ?
                "border-radius:6px;" +
                "overflow:hidden;" +
                "box-shadow:0 0 " + self.options.lightboxShadowSize + " " + self.options.lightboxShadowColor + ";"
            : "")
        );
        iframeParent.appendChild(iframe);

        if (isCustomisation) {
            var customisation = JSON.stringify(self.options.customisation);

            var iframeForm = document.createElement("form");
            iframeForm.id = self.wpName + "-iframe-form";
            iframeForm.method = "POST";
            iframeForm.action = self.options.iframeUrl;
            iframeForm.target = self.wpName + "-iframe";

            var customisationField = document.createElement("input");
            customisationField.type = "hidden";
            customisationField.name = "customisation";
            customisationField.value = customisation;

            iframeForm.appendChild(customisationField);
            iframeParent.appendChild(iframeForm);
        }

        return iframeParent;
    };

    var injectElementIntoTarget = function(domElement) {
        var self = this;
        var target = document.getElementById(self.options.target);

        if (typeof(target) !== 'undefined' && target !== null) {

            if (target.childNodes.length > 0) {
                while (target.firstChild) {
                    target.removeChild(target.firstChild);
                }
            }

            target.appendChild(domElement);

        } else {
            writeMessage.call(self, "ERROR: HTML target, specified by 'target' parameter, could not be found - target: " + self.options.target);
        }
    };

    var submitFormData = function() {
        var self = this;
        var element = document.getElementById(self.wpName + "-iframe-form");
        if (element != null) {
            element.submit();
        }
    };

    var bindLightboxAccessibility = function() {

        var self = this;

        var tags = ['a', 'input', 'select'];
        var target = document.getElementById(self.options.target);

        writeMessage.call(self, 'Setting accessibility features');

        for (var i = 0; i < tags.length; i++) {

            var tagRemove = document.getElementsByTagName(tags[i]);

            for (var a = 0; a < tagRemove.length; a++) {

                tagRemove[a].tabIndex = -1;

            }
        }

        for (var b = 0; b < tags.length; b++) {

            var tagAdd = target.getElementsByTagName(tags[b]);

            for (var c = 0; c < tagAdd.length; c++) {

                tagAdd[c].removeAttribute('tabindex');

            }

        }
    };

    var removeLightboxAccessibility = function(){
        var tags = ['a', 'input', 'select'];

        writeMessage.call(self, "Removing accessibility changes...");

        for (var i = 0; i < tags.length; i++) {
            var tagName = document.getElementsByTagName(tags[i]);

            for (var a = 0; a < tagName.length; a++) {

                tagName[a].removeAttribute('tabindex');
            }
        }

    };

    var destroyLightbox = function() {

        var self = this;

        var lb = document.getElementById(self.prefix);
        var trigger = document.getElementById(self.options.trigger);

        writeMessage.call(self, "Destroying lightbox...");

        if (lb && lb.parentNode) {
            lb.parentNode.removeChild(lb);
        }

        if (self.options.accessibility) {
            removeLightboxAccessibility.call(self);
            if (trigger && trigger.focus) {
                trigger.focus();
            }
        }
    };

    var destroyIframe = function() {
        var self = this;

        var target = document.getElementById(self.options.target);
        target.innerHTML = '';

        if (self.options.inject === 'onload') {
            if (window.removeEventListener) {
                window.removeEventListener('load', self.onloadInject, false);
            } else if (window.attachEvent) {
                window.detachEvent('onload', self.onloadInject);
            } else {
                writeMessage.call(self, 'ERROR: the  browser does not support an onload event-handler');
            }
        }

    };

    var destroy = function() {
        var self = this;

        if (self.options.type === 'iframe') {
            destroyIframe.call(self);
        } else if (self.options.type === 'lightbox') {
            destroyLightbox.call(self);
        } else {
            writeMessage.call(self, 'WARN: injected content not cleaned up');
        }

        destroyPostMessageHandler.call(self);
    };

    var destroyPostMessageHandler = function () {
        if (this.postMessageHandler != null) {
            if (window.removeEventListener) {
                window.removeEventListener("message", this.postMessageHandler);
            } else {
                window.detachEvent("onmessage", this.postMessageHandler);
            }
            writeMessage.call(self, "INFO: destroyed post message event listener");
        }
    };

    var resize = function (height, isPostMessage) {
        var self = this;
        var iframe = getIframe.call(self);

        if (iframe != null) {

            if (isPostMessage || !self.isPostMessage) {

                if (!self.options.hideContent) {

                    iframe.style.height = parseInt(height) + 'px';

                    writeMessage.call(self, "iframe resized - height: " + height);

                    if (self.options.type === 'lightbox') {
                        var body = document.body;
                        var html = document.documentElement;

                        var pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

                        if (pageHeight < height) {
                            document.body.style.height = (parseInt(height)) + "px";
                            writeMessage.call(self, "lightbox container resized - height: " + height);
                        }
                    }

                    var payload = {
                        "source": "hpp",
                        "action": "resize-complete"
                    };
                    sendPostMesage.call(self, payload);

                } else {
                    writeMessage.call(self, "WARN: skipped resizing iframe as hideContent is enabled")
                }

            } else {
                writeMessage.call(self, "WARN: iframe not found for height resize");
            }

        } else {
            writeMessage.call(self, "WARN: iframe not found for height resize");
        }
    };

    var getIframe = function () {
        var self = this;

        var targetName = self.wpName + "-iframe";
        var iframe = document.getElementById(targetName);
        if (iframe == null) {
            writeMessage.call(self, "WARN - unable to find iframe - target name: " + targetName);
        }

        return iframe;
    };

    var redirect = function(url) {

        top.location.href = url;
    };

    var checkNumber = function(val) {
        if(typeof(val) === 'string'){
            return true;
        }

        return isNaN(val);
    };

    var writeMessage = function(msg){

        var self = this;
        if (self && self.options && self.options.debug && console && console.log) {
            console.log("Worldpay HPP JavaScript SDK - " + msg);
        }
    };

    var scroll = function(pageOffset){
        var self = this;


        var integrationType = self.options.type;
        var disableScrolling = self.options.disableScrolling;

        if (!disableScrolling && integrationType === "iframe") {

            var iframe = getIframe.call(self);

            var viewportOffsetTop = iframe.getBoundingClientRect().top;

            var currentScrollPosition = window.pageYOffset;
            var scrollPosition = currentScrollPosition + viewportOffsetTop;

            if (pageOffset != null) {
                scrollPosition += pageOffset;
            }

            document.body.scrollTop = scrollPosition;
            document.documentElement.scrollTop = scrollPosition;

            writeMessage.call(self, "changed vertical scroll position: " + scrollPosition);
        }
    };

    var populateForm = function(keyValuePairsObject) {
        var self = this;

        if (keyValuePairsObject == null) {
            writeMessage.call(self, "unable to populate fields on payment pages, null or empty object provided");
        } else if (!keyValuePairsObject.constructor || keyValuePairsObject.constructor !== Object) {
            writeMessage.call(self, "unable to populate fields on payment pages, argument must be a JSON object");
        } else {

            var valid = true;

            for (var key in keyValuePairsObject) {
                var value = keyValuePairsObject[key];
                if (value != null && (!value.constructor || !(value.constructor === String || value.constructor === Number))) {
                    writeMessage.call(self, "unable to populate fields on payment pages, property '" + key + "' must be a string");
                    valid = false;
                }
            }

            if (valid) {
                writeMessage.call(self, "populating fields on payment pages...");

                var payload = {
                    "source": "hpp",
                    "action": "populate-form",
                    "data": keyValuePairsObject
                };
                sendPostMesage.call(self, payload);
            }

        }
    };

    var submitForm = function() {
        var self = this;

        writeMessage.call(self, "submitting form on payment pages...");
        var payload = {
            "source" : "hpp",
            "action" : "submit-form"
        };
        sendPostMesage.call(self, payload);
    };


    var messagingSetup = function () {
        var self = this;

        destroyPostMessageHandler.call(self);

        this.postMessageHandler = function (event) {
            messagingHandleMessage.call(self, event);
        };

        if (window.addEventListener) {
            window.addEventListener("message", this.postMessageHandler, false);
        } else {
            window.attachEvent("onmessage", this.postMessageHandler);
        }
    };

    var messagingHandleMessage = function (event) {

        var self = this;

        this.isPostMessage = true;

        var origin = event.origin || event.originalEvent.origin;
        var url = self.options.url;

        if (url != null && url.indexOf(origin) != 0) {
            writeMessage.call(self, "WARN - post-message from different source - origin: " + origin + ", ticketUrl: " + url);

            if (!self.options.debug) {
                return;
            }
        }

        var data;

        try {
            data = JSON.parse(event.data);
        } catch (e) {
            data = null;
        }

        if (data != null && data.source == "hpp" && data.action != null && data.args != null) {


            if (data.id != null && data.id.length > 0 && data.id != self.instanceId) {
                writeMessage.call(self, "ignoring message as different instance - req: " + data.id + ", ours: " + self.instanceId);
            } else {
                switch (data.action) {
                    case "check":
                        messagingHandleMessageCheck.call(self);
                        break;
                    case "apple-pay-availability":
                        messagingHandleApplePayAvailabilityCallback.call(self);
                        break;
                    case "do-apple-pay":
                        messagingHandleApplePayCreateSessionCallback.call(self, data);
                        break;
                    case "do-apple-pay-2":
                        messagingHandleApplePayCreateSessionCallbackVersion2.call(self, data);
                        break
                    case "resize":
                        messagingHandleMessageResize.call(self, data);
                        break;
                    case "redirect":
                        messagingHandleMessageRedirect.call(self, data);
                        break;
                    case "scroll":
                        messagingHandleMessageScroll.call(self, data);
                        break;
                    case "result":
                        messagingHandleResultCallback.call(self, data);
                        break;
                    case "flow":
                        messagingHandleFlowCallback.call(self, data);
                        break;
                    default:
                        writeMessage.call(self, "unhandled action: " + data.action);
                        break;
                }
            }

        } else {
            writeMessage.call(self, "malformed post-message received - " + JSON.stringify(data));
        }
    };

    var messagingHandleMessageCheck = function () {
        var self = this;

        writeMessage.call(self, "received postMessage mechanism check, replying back");

        var sdkInfo = buildSdkInfo.call(self);

        var payload = {
            "source" : "hpp",
            "action" : "check-working",
            "sdkInfo" : sdkInfo
        };
        sendPostMesage.call(self, payload);
    };

    var messagingHandleApplePayAvailabilityCallback = function () {
        var self = this;
        writeMessage.call(self, "received postMessage: apple-pay-availability");

        var isApplePayAvailable = "false";

        if (window.ApplePaySession && ApplePaySession.canMakePayments) {
            isApplePayAvailable = "true";
        }

        var payload = {
            "source" : "hpp",
            "action" : "apple-pay-availability-reply",
            "isApplePayAvailable" : isApplePayAvailable
        };

        sendPostMesage.call(self, payload);
    }

    var messagingHandleApplePayCreateSessionCallback = function (data) {
        var self = this;
        writeMessage.call(self, "received postMessage: do-apple-pay");

        var applePayPaymentRequest = data.args.applePayPaymentRequest;
        var applePayControllerCreateSessionUrlWithHost = data.args.applePayControllerCreateSessionUrlWithHost;
        var applePayControllerAuthorizePaymentUrlWithHost = data.args.applePayControllerAuthorizePaymentUrlWithHost;
        var supportedVersion = data.args.supportedVersion;

        var versionToUse = getSupportedVersion.call(self, supportedVersion);
        var session = new ApplePaySession(versionToUse, applePayPaymentRequest);

        session.onvalidatemerchant = function(event) {
            var validationUrl = event.validationURL;
            var initiativeContext = window.location.hostname;
            getPromiseXmlCall.call(self, applePayControllerCreateSessionUrlWithHost, {"validationUrl" : validationUrl, "initiativeContext" : initiativeContext}).then(
                function(value) {
                    session.completeMerchantValidation(JSON.parse(value));
                },
                function(error) {
                    session.abort();
                    sendApplePayRedirectReply.call(self, "ERROR", error);
                });
        };

        session.onpaymentauthorized = function(event) {
            var token = event.payment.token;
            getPromiseXmlCall.call(self, applePayControllerAuthorizePaymentUrlWithHost, token).then(
                function(value) {
                    if (value) {
                        switch (value) {
                            case "SUCCESS" :
                                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                                sendApplePayRedirectReply.call(self, "SUCCESS", "");
                                break;
                            case  "FAILURE" :
                                session.completePayment(ApplePaySession.STATUS_FAILURE);
                                sendApplePayRedirectReply.call(self, "FAILURE", "");
                                break;
                            default :
                                session.completePayment(ApplePaySession.STATUS_FAILURE);
                                sendApplePayRedirectReply.call(self, "ERROR", "");
                                break;
                        }
                    } else {
                        session.completePayment(ApplePaySession.STATUS_FAILURE);
                        sendApplePayRedirectReply.call(self, "ERROR", "");
                    }
                },
                function(error) {
                    sendApplePayRedirectReply.call(self, "ERROR", error);
                });
        };

        session.begin();
    }

    var messagingHandleApplePayCreateSessionCallbackVersion2 = function (data) {
        var self = this;
        writeMessage.call(self, "received postMessage: do-apple-pay-2");

        var applePayPaymentRequest = data.args.applePayPaymentRequest;
        var applePayControllerCreateSessionUrlWithHostVersion2 = data.args.applePayControllerCreateSessionUrlWithHostVersion2;
        var applePayControllerAuthorizePaymentUrlWithHostVersion2 = data.args.applePayControllerAuthorizePaymentUrlWithHostVersion2;
        var supportedVersion = data.args.supportedVersion;

        var versionToUse = getSupportedVersion.call(self, supportedVersion);
        var session = new ApplePaySession(versionToUse, applePayPaymentRequest);

        session.onvalidatemerchant = function(event) {
            var validationUrl = event.validationURL;
            var initiativeContext = window.location.hostname;
            getPromise.call(self, applePayControllerCreateSessionUrlWithHostVersion2, {"validationUrl" : validationUrl, "initiativeContext" : initiativeContext}).then(
                function(value) {
                    session.completeMerchantValidation(value);
                },
                function(error) {
                    session.abort();
                    sendApplePayRedirectReply.call(self, "ERROR", error);
                });
        };

        session.onpaymentauthorized = function(event) {
            var token = event.payment.token;
            getPromise.call(self, applePayControllerAuthorizePaymentUrlWithHostVersion2, token).then(
                function(value) {
                    if (value) {
                        var authStatus = value.status;
                        switch (authStatus) {
                            case "SUCCESS" :
                                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                                sendApplePayRedirectReply.call(self, "SUCCESS", "");
                                break;
                            case  "FAILURE" :
                                session.completePayment(ApplePaySession.STATUS_FAILURE);
                                sendApplePayRedirectReply.call(self, "FAILURE", "");
                                break;
                            default :
                                session.completePayment(ApplePaySession.STATUS_FAILURE);
                                sendApplePayRedirectReply.call(self, "ERROR", "");
                                break;
                        }
                    } else {
                        session.completePayment(ApplePaySession.STATUS_FAILURE);
                        sendApplePayRedirectReply.call(self, "ERROR", "");
                    }
                },
                function(error) {
                    sendApplePayRedirectReply.call(self, "ERROR", error);
                });
        };

        session.begin();

    }

    var getSupportedVersion = function(currentHighest) {
        var versionToUse = currentHighest;
        versionToUseLoop : while (versionToUse > 1) {
            var isSupported = window.ApplePaySession.supportsVersion(versionToUse);
            if (isSupported) {
                break versionToUseLoop;
            } else {
                versionToUse -= 1;
            }
        }
        return versionToUse;
    };

    var sendApplePayRedirectReply = function(status, error) {
        var self = this;
        var payload = {
            "source" : "hpp",
            "action" : "apple-pay-redirect-reply",
            "status" : status,
            "error" : error
        };
        sendPostMesage.call(self, payload);
    }

    var getPromise = function (url, body) {
        return new Promise(function (resolve, reject) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            }).then( function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    var errorMessage = {
                        "issueType": "js-apple-pay",
                        "message": "Failed for : " + url,
                        "statusCode": response.status,
                        "statusMessage": response.statusText
                    };
                    reject(errorMessage);
                }
            }).then(function(json) {
                resolve(json);
            }).catch(function(error) {
                var errorMessage = {
                    "issueType": "js-apple-pay",
                    "message": "Failed for : " + url,
                    "statusCode": error.statusCode,
                    "statusMessage": error.statusMessage
                };
                reject(errorMessage);
            });
        })
    };


    var getPromiseXmlCall = function (url, body) {
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(xhr.response);
                } else {
                    var errorMessage = {
                        "issueType": "js-apple-pay",
                        "message": "Failed for : " + url,
                        "statusCode": this.status,
                        "statusMessage": xhr.statusText
                    };
                    reject(errorMessage);
                }
            };
            xhr.onerror = function () {
                var errorMessage = {
                    "issueType": "js-apple-pay",
                    "message": "Failed for : " + url,
                    "statusCode": this.status,
                    "statusMessage": xhr.statusText
                };
                reject(errorMessage);
            };
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.send(JSON.stringify(body));
        });
    };

    var messagingHandleMessageResize = function (data) {
        var self = this;

        var height = data.args.height;
        writeMessage.call(self, "resizing iframe... - height: " + height);

        resize.call(self, height, true);
    };

    var messagingHandleMessageRedirect = function (data) {
        var self = this;

        var url = data.args.url;
        writeMessage.call(self, "received redirect message... - url: " + url);

        redirect.call(self, url);
    };

    var messagingHandleMessageScroll = function (data) {
        var self = this;

        var pageOffset = data.args.scrollingOffset;
        writeMessage.call(self, "scrolling payments page...");
        scroll.call(self, pageOffset);
    };

    var messagingHandleResultCallback = function(data) {
        var self = this;
        invokeMerchantCallback.call(self, "result", self.options.resultCallback, data.args.result);
    };

    var messagingHandleFlowCallback = function(data) {
        var self = this;
        invokeMerchantCallback.call(self, "flow", self.options.flowCallback, data.args);
    };

    var invokeMerchantCallback = function(name, callback, result) {
        if (callback == null) {
            writeMessage.call(self, "no " + name + " callback function specified, skipping " + name + " callback invocation");
        } else if (result == null) {
            writeMessage.call(self, "null " + name + " result from payment pages, skipping " + name + " callback invocation");
        } else {
            writeMessage.call(self, "invoking " + name + " callback");
            callback(result);
        }
    };

    var buildSdkInfo = function() {
        var self = this;
        var sdkInfo;

        if (!self.sdkInfoAlreadySent) {

            var version = self.majorVersion;
            var saved = isLibrarySaved.call(self);
            var integrationType = self.options.type;
            var insideFrame = isInsideFrame.call(self);
            var parentCrossDomain = isParentDomainDifferent.call(self);
            var manualOnload = isManualOnloadIframe.call(self);
            var isResultCallback = (self.options.resultCallback != null);
            var isFlowCallback = (self.options.flowCallback != null);
            var injectionType = self.options.inject;
            var isCustomisation = (self.options.customisation != null);

            sdkInfo = {
                "version": version,
                "saved" : saved,
                "integrationType": integrationType,
                "insideFrame": insideFrame,
                "parentCrossDomain": parentCrossDomain,
                "manualOnload": manualOnload,
                "debug" : self.options.debug,
                "resultCallback" : isResultCallback,
                "flowCallback" : isFlowCallback,
                "inject": injectionType,
                "customisation": isCustomisation,
                "disableScrolling" : self.options.disableScrolling,
                "hideContent" : self.options.hideContent
            };

            self.sdkInfoAlreadySent = true;

        } else {
            sdkInfo = {
                "version": version,
                "alreadySent" : "true"
            };
        }

        return sdkInfo;
    };

    var isInsideFrame = function() {
        try {
            var test = top != window;
            return test;
        } catch (e) {
            return true;
        }
    };

    var isParentDomainDifferent = function() {
        try {
            var test = parent != null && parent.document;
            return !test;
        } catch (e) {
            return true;
        }
    };

    var isManualOnloadIframe = function() {
        var self = this;
        var result = false;

        if (self.options.type == "iframe") {
            result = self.isFakeLoadEvent;
        }

        return result;
    };

    var isLibrarySaved = function() {
        var result = true;

        var scripts = document.getElementsByTagName("script");
        var script, url;

        for (var i = 0; result && i < scripts.length; i++) {
            script = scripts[i];
            url = script.src;
            if (url != null && url.indexOf(".worldpay") > 0) {
                result = false;
            }
        }

        return result;
    };

    var sendPostMesage = function (payload) {
        var self = this;

        var payloadAsText = JSON.stringify(payload);

        var iframe = getIframe.call(self);
        if (iframe != null && iframe.contentWindow && iframe.contentWindow.postMessage) {
            var origin = extractPaymentPagesOrigin.call(self);
            iframe.contentWindow.postMessage(payloadAsText, origin);
        } else {
            writeMessage.call(self, "unable to send message to payment pages, iframe not available - has it been injected on your page?");
        }
    };

    var extractPaymentPagesOrigin = function() {
        var self = this;
        var ticketUrl = self.options.url;
        var originIndex = ticketUrl.indexOf("/", 10);
        var origin = ticketUrl.substring(0, originIndex);
        return origin;
    };

    return {
        setup: setup,
        destroy: destroy,
        resize: resize,
        redirect: redirect,
        scroll: scroll,
        populateForm: populateForm,
        submitForm: submitForm
    };

}());
